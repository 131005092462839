<template>
  <div>
    <div class="leisure_buttons_group">
      <router-link tag="button" class="bw3 btn_minigame_comp"
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_EOS}"
                   :to="{path:'/leisure/eospowerball1m', query: {t: new Date().getTime()}}">
        <img src="../../assets/images/icon/cenha/mini/ntry.png"/> 엔트리
      </router-link>
      <router-link tag="button" class="bw3 btn_minigame_comp"
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_CROWN}"
                   :to="{path:'/leisure/sutda', query: {t: new Date().getTime()}}">
        <img src="../../assets/images/icon/cenha/mini/crown.png"/> 크라운
      </router-link>
      <router-link tag="button" class="bw3 btn_minigame_comp"
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_SKY}"
                   :to="{path:'/leisure/sky_speed_bakara', query: {t: new Date().getTime()}}">
        <img src="../../assets/images/icon/cenha/mini/skypark.png"/> 스카이파크
      </router-link>
    </div>
    <div class="leisure_buttons_group">

      <router-link tag="button" class="bw3 btn_minigame_comp"
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_BET365}"
                   :to="{path:'/leisure/bet365_superleague', query: {t: new Date().getTime()}}">
        <img src="../../assets/images/icon/cenha/mini/Bet365.png"/> BET365
      </router-link>
      <router-link tag="button" class="bw3 btn_minigame_comp "
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_STAR_SADALI}"
                   :to="{path:'/leisure/ssd1', query: {t: new Date().getTime()}}">
        <img src="../../assets/images/icon/cenha/mini/bos.png"/> 보스코어
      </router-link>
      <router-link tag="button" class="bw3 btn_minigame_comp "
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_NAMED}"
                   :to="{path:'/leisure/npowerball3s', query: {t: new Date().getTime()}}">
        <img src="../../assets/images/icon/cenha/mini/named.png"/> 네임드
      </router-link>

    </div>



    <!--        <div class="leisure_buttons_group">-->
    <!--            <router-link tag="button" class="bw2 btn_minigame_comp"-->
    <!--                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_TOKENGAME}"-->
    <!--                         :to="{path:'/tgame365/tgame365baccarat', query: {t: new Date().getTime()}}">-->
    <!--                토큰게임-->
    <!--            </router-link>-->

    <!--            <router-link tag="button" class="bw2 btn_minigame_comp"-->
    <!--                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_LOTUS}"-->
    <!--                         :to="{path:'/leisure/lotus_bakara', query: {t: new Date().getTime()}}">-->
    <!--                로투스-->
    <!--            </router-link>-->
    <!--            <router-link tag="button" class="bw2 btn_minigame_comp"-->
    <!--                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_SKY}"-->
    <!--                         :to="{path:'/leisure/sky_speed_bakara', query: {t: new Date().getTime()}}">-->
    <!--                스카이파크-->
    <!--            </router-link>-->

    <!--            <router-link tag="button" class="bw3 btn_minigame_comp"-->
    <!--                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_MGM}"-->
    <!--                         :to="{path:'/leisure/mgm_bakara', query: {t: new Date().getTime()}}">-->
    <!--                MGM-->
    <!--            </router-link>-->
    <!--            <router-link tag="button" class="bw3 btn_minigame_comp"-->
    <!--                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_CROWN}"-->
    <!--                         :to="{path:'/leisure/sutda', query: {t: new Date().getTime()}}">-->
    <!--                크라운-->
    <!--            </router-link>-->
    <!--        </div>-->



  </div>


</template>
<script>

import leisureConst from "../../common/leisureConst";

export default {
  name: "LeisureGameCompLinks",
  data() {
    return {
      leisureConst: leisureConst,

    }
  },
  methods: {
    commingsoon() {
      alert('준비중입니다')
    },
  },
  created() {

  }
}
</script>

<style scoped>
.leisure_buttons_group img {
  width: 25px;
}
</style>